import { makeStyles, Theme } from '@material-ui/core';
import { ResponsivePie } from '@nivo/pie';
import { useEffect, useState } from 'react';
import { format, startOfWeek } from 'date-fns';
import { getMoodsByTeamIdAndDateRange } from '../../shared/services/MoodService';
import { IDateRange } from '../../shared/interfaces/DateRange';
import DateRangeSelection from './partials/DateRangePicker';
import { User } from '../../shared/interfaces/User';
import { MoodForChart } from '../../shared/interfaces/Mood';
import BeatLoaderComponent from '../SurveyContainerComponent/util/BeatLoader';


const useStyles = makeStyles((theme: Theme) => ({
    loader: {
        textAlign: 'center',
        marginTop: 50
    },
    chartContainer: {
        height: '70%',
        width: '100%',
    },
    pieContainer: {
        width: '80%',
        height: '80%',
        margin: '0 auto'
    },
    datePickerContainer: {
        display: 'flex',
        justifyContent: 'center',
        margin: '0 auto',
    },
    title: {
        marginBottom: 20,
        marginLeft: 20,
        paddingTop: 10
    }
}));

interface IPieChartComponent {
    currentUser: User
    setDateRange: (dateRange: Date[]) => void
}

interface IPieChart {
    id: string
    label: string
    value: number
}

const PieChartComponent = (props: IPieChartComponent) => {
    const classes = useStyles();
    const {
        currentUser
    } = props;
    const today: Date = new Date(new Date().setUTCHours(22, 59, 59, 999))
    const [moods, setMoods] = useState < MoodForChart[] > ([])
    const [chartData, setChartData] = useState < IPieChart[] > ([]);
    const [dateRange, setDateRange] = useState < Date[] > ([getMondayOfWeek(), today]);

    useEffect(() => {
        if (currentUser) {
            getMoods();
        }

    }, [currentUser === undefined, dateRange]);

    useEffect(() => {
        if (moods.length > 0) {
            setChart();
        }
    }, [moods])

    useEffect(() => {
        props.setDateRange(dateRange)
    }, [dateRange])


    function getMondayOfWeek() {
        return startOfWeek(new Date().setUTCHours(23, 0, 0, 0), {
            weekStartsOn: 1
        });
    }

    async function getMoods() {
        const dates: IDateRange = {
            startDate: format(dateRange[0], "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
            endDate: format(dateRange[1], "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")
        }

        await getMoodsByTeamIdAndDateRange(currentUser, dates).then((result) => {
            if (result) {
                const moods: MoodForChart[] = JSON.parse(result.body)
                setMoods(moods)
            }
        })
    }

    function setChart() {
        const counterArray = sumUpMoods()
        const moodData: IPieChart[] = [{
                id: "Glücklich",
                label: "Glücklich",
                value: counterArray[4],
            },
            {
                id: "Zufrieden",
                label: "Zufrieden",
                value: counterArray[3],
            },
            {
                id: "Neutral",
                label: "Neutral",
                value: counterArray[2],
            },
            {
                id: "Unzufrieden",
                label: "Unzufrieden",
                value: counterArray[1],
            },
            {
                id: "Unglücklich",
                label: "Unglücklich",
                value: counterArray[0],
            }
        ]

        setChartData(moodData)
    }

    function sumUpMoods() {
        let counterArray = [0, 0, 0, 0, 0]

        for (let mood of moods) {
            if (mood.value === 1) {
                counterArray[0] += 1
            } else if (mood.value === 2) {
                counterArray[1] += 1
            } else if (mood.value === 3) {
                counterArray[2] += 1
            } else if (mood.value === 4) {
                counterArray[3] += 1
            } else if (mood.value === 5) {
                counterArray[4] += 1
            }
        }

        return counterArray
    }

    return (
        <div className={classes.chartContainer}>
            <h1 className={classes.title}>Individuelle Übersicht</h1>
            <div className={classes.datePickerContainer}>
                <DateRangeSelection setDateRange={setDateRange} dateRange={dateRange} today={today} />
            </div>
            { moods.length > 0 &&
            <>
                <div className={classes.pieContainer}>
                    <ResponsivePie
                        data={chartData}
                        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                        innerRadius={0.5}
                        padAngle={0.7}
                        cornerRadius={3}
                        colors={['#FAD201', '#78AD57', '#218167', '#13515B', '#113869']}
                        borderWidth={1}
                        borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
                        arcLabelsTextColor="#fff"
                        legends={[
                            {
                                anchor: 'bottom',
                                direction: 'row',
                                justify: false,
                                translateX: 0,
                                translateY: 56,
                                itemsSpacing: 0,
                                itemWidth: 100,
                                itemHeight: 18,
                                itemTextColor: '#999',
                                itemDirection: 'left-to-right',
                                itemOpacity: 1,
                                symbolSize: 18,
                                symbolShape: 'circle',
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemTextColor: '#000'
                                        }
                                    }
                                ]
                            }
                        ]}
                    />
                </div>
            </>
            }
            { moods.length === 0 &&
                <BeatLoaderComponent />
            }
        </div>
    )
}

export default PieChartComponent;