import API from "@aws-amplify/api"
import { User } from "../interfaces/User";
import { IDateRange } from "../interfaces/DateRange";
import { Mood } from "../interfaces/Mood";
import { graphqlOperation} from "aws-amplify";
import { createMood } from "../../graphql/mutations";

export async function getDateChartMoodsByTeamID(currentUser: User, dateRange: IDateRange): Promise < any > {
       const searchParams = {
              body: {
                     teamID: currentUser.teamID,
                     startDate: dateRange.startDate,
                     endDate: dateRange.endDate
              },
       };

       try {
              return await API.post(
                     'teammood_prototyp',
                     '/teammood/getDateChartMoods',
                     searchParams
              )
       } catch (error) {
              console.log(error)
       }
}

export async function getMoodsByTeamIdAndDateRange(currentUser: User, dateRange: IDateRange): Promise < any > {
       const searchParams = {
              body: {
                     teamID: currentUser.teamID,
                     startDate: dateRange.startDate,
                     endDate: dateRange.endDate
              },
       };

       try {
              return await API.post(
                     'teammood_prototyp',
                     '/teammood/getMoodsByTeamIdAndDateRange',
                     searchParams
              )
       } catch (error) {
              console.log(error)
       }
}

export async function getCommentsByTeamIdAndDateRange(currentUser: User, dateRange: IDateRange): Promise < any > {
       const searchParams = {
              body: {
                     teamID: currentUser.teamID,
                     startDate: dateRange.startDate,
                     endDate: dateRange.endDate
              },
       };

       try {
              return await API.post(
                     'teammood_prototyp',
                     '/teammood/getCommentsByTeamIdAndDateRange',
                     searchParams
              )
       } catch (error) {
              console.log(error)
       }
}

export async function createMoodInDb(mood: Mood) {
       try {
              await API.graphql(graphqlOperation(createMood, {
                     input: mood
              }))
       } catch (error) {
              console.log(error)
       }
}