import { MobileDateRangePicker } from '@mui/lab';
import { DateRange } from '@mui/lab/DateRangePicker/RangeTypes';
import { TextField } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';

interface IDateRangeSelection {
    today: Date;
    dateRange: Date[];
    setDateRange: (selectedDateRange: DateRange<Date>) => void
}

const DateRangeSelection = (props: IDateRangeSelection) => {
    function setTimeBySelection(selectedDateRange: DateRange<Date>) {
        if (selectedDateRange['0']) {
            selectedDateRange['0'] = new Date(selectedDateRange['0'].setUTCHours(23,0,0,0))
        }

        if (selectedDateRange['1']) {
            selectedDateRange['1'] = new Date(selectedDateRange['1'].setUTCHours(46,59,59,59))
        }

        props.setDateRange(selectedDateRange)
    }

    return (
        <MobileDateRangePicker
            startText="Startdatum"
            endText="Enddatum"
            value={props.dateRange as any}
            onAccept={(newValue) => {
                setTimeBySelection(newValue)
            }}
            onChange={() => {}}
            maxDate={props.today}
            disableCloseOnSelect={true}
            inputFormat="dd.MM.yyyy"
            toolbarTitle="Datum auswählen"
            cancelText="Abbrechen"
            renderInput={(startProps, endProps) => (
                <>
                    <TextField {...startProps}/>
                    <Box sx={{ mx: 2 }}> bis </Box>
                    <TextField {...endProps} />
                </>
            )}
        />)
}

export default DateRangeSelection;